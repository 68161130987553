import { Link, graphql } from "gatsby"
import React, { useState } from 'react';

import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import CarouselSlider from "../components/carousel-slider"

import Layout from "../components/layout"
import Seo from "../components/seo"


const AboutPage = ({ data, location }) => {

  
  const [progress, setProgress] = useState(0);
  const [entered, setEntered] = useState(false);

  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };
  
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const characters = data.webiny.listAlphaBetasCharacters.data
    const postData = data.webiny.listAlphaBetasPosts.data
  
    if (characters.length === 0) {
      return (
        <Layout location={location} title={siteTitle}>
          <Seo title="All Characters" />
          <p>
            No Characters found.
          </p>
        </Layout>
      )
    }
  
    return (
      <Layout location={location} title={siteTitle} className="bg-black">
        <Seo title="About the Show" />
        <div className="aboutPage">
          <div className="inline-block w-full pt-20">
              <div className="mx-auto py-10 md:py-20 px-5 m-0 w-full md:w-1/2">
                  <div className="text-center">
                    <h1 className="text-4xl md:text-5xl shadow-2xl font-GodaBold uppercase mb-5 text-ab-yellow-600">About the Show</h1>
                    <div className="">
                      <p className="font-CobeSemiBold leading-6 text-lg">In Alpha Betas, video games are powering the world thanks to a massive, top-secret CIA program. The show follows an elite virtual strike force of four top gamers as they drop into the virtual realms of video games to fix potentially world-ending issues. Known as the Alpha Team, these four willfully reckless and dangerously arrogant guys are the tip of a five-hundred billion dollar US Government spear sent to be heroes in high-octane pixelated worlds.</p>
                    </div>
                  </div>
              </div>
          </div>
          <div className="bg-ab-blue-100 w-full md:px-auto py-20">
            <div className="mx-auto py-10 md:py-0 px-5 m-0 w-full md:w-1/2">
                  <div className="text-center">
                    <h2 className="text-4xl md:text-5xl shadow-2xl font-GodaBold uppercase mb-5 text-ab-yellow-600">Who is Alpha Team?</h2>
                    <div className="">
                      <p className="text-center font-CobeSemiBold text-xl leading-6 md:text-2xl">Watch the recap of Episode 1 below!</p>
                    </div>
                  </div>
              </div>
              <div className="aspect-w-15 aspect-h-8 mx-5 md:m-10">
                <iframe 
                  src='https://www.youtube.com/embed/K1kbP-xLE_k?autoplay=1&mute=1'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='Alpha Betas Show'
                  className="rounded-3xl" 
                  frameborder="0" 
                  allowfullscreen
                />
              </div>
          </div>
          <div className="inline-block bg-ab-blue-300 w-full py-20">
                  
            <h1 className="text-4xl md:text-5xl shadow-2xl font-GodaBold uppercase mb-5 text-center text-ab-yellow-600">Characters</h1>

              <div className="mx-auto max-w-4xl py-5 px-4 sm:px-6 lg:py-5 lg:px-8">
                  <div className="text-white text-sm grid grid-cols-6 gap-x-6 gap-y-12 space-y-0 lg:gap-x-8">
                    {characters.map(character => {
                        return (
                            <div 
                              key={character.slug} 
                              className="mb-5 col-span-3 md:col-span-2"
                              >
                                
                                  <button key={character.id} onClick={() => handleCharacterClick(character)}>
                                      <div 
                                        className="rounded-lg p-5 pb-0 bg-cover overflow-hidden hover:scale-110 ease-in-out duration-300"
                                        style={{ backgroundImage: `url(${character.characterAdditionalAsset})` }}
                                      >
                                        <img 
                                          src={character.characterProfileImage} 
                                          className="featureImage object-cover hover:scale-110 ease-in-out duration-500" 
                                        />
                                      </div>
                                  </button>
                                  <h2 className="my-3 text-xl uppercase text-center">
                                      <div className="w-full font-GodaBold">{character.characterName}</div>
                                        {character.characterVoicedBy ? 
                                          <span 
                                            className="text-sm font-CobeSemiBold capitalize inline-block"
                                          >Voiced by&nbsp; 
                                          <Link className="" 
                                            to={character.characterVoicedByLink} 
                                            target="_blank"
                                          >
                                            {character.characterVoicedBy}
                                          </Link>
                                          </span>
                                        :
                                          null
                                        }
                                        {character.characterVoicedBySlug 
                                        ? 
                                        <Link 
                                          className="text-sm p-2 text-center my-3 border-2 font-GodaRegular antialiased border-black rounded-full bg-ab-yellow-600 text-black capitalize w-full after:content-externalLink after:absolute inline-block after:w-3 after:h-3 after:ml-2 after:justify-end after:inline-block after:items-center hover:scale-105 ease-in-out duration-500" 
                                          to={character.characterVoicedByLink} 
                                          target="_blank"
                                        >{character.characterVoicedBySlug}</Link>: null }
                                  </h2>
                            </div>
                        )
                        })}
                        <div>
                          {selectedCharacter && (
                            <Modal open onClose={() => setSelectedCharacter(null)}>
                              <div className="bg-bodyPattern rounded-3xl border-4 border-ed-blue-200 text-white p-5 md:p-10 m-1/3">
                                <div className="grid grid-cols-auto md:grid-cols-6 md:flex gap-4 md:gap-8 items-center justify-center">
                                  <div className="md:col-span-2 hover:scale-105 ease-in-out duration-300">
                                    <img src={selectedCharacter.characterImage} alt={selectedCharacter.characterName} className="mx-auto w-6/12 md:w-full" />
                                  </div>
                                  <div className="md:col-span-4">
                                    <h3 className="my-3 text-3xl uppercase font-GodaBold">{selectedCharacter.characterName}</h3>
                                      {selectedCharacter.characterVoicedBy ? 
                                        <span 
                                          className="text-md font-normal capitalize inline-block mb-5 font-CobeSemiBold"
                                        >Voiced by&nbsp; 
                                        <Link                   
                                          to={selectedCharacter.characterVoicedByLink} 
                                          target="_blank"
                                        >
                                          {selectedCharacter.characterVoicedBy}
                                        </Link>
                                        </span>
                                      :
                                        null
                                      }
                                    <p 
                                      className="text-md font-CobeSemiBold leading-5">{selectedCharacter.characterShortBio}</p>
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </div>
                  </div>
              </div>
          </div>


          <div className="bg-ab-blue-100 w-full py-10 md:py-20 px-10">
            <div className="block max-w-7xl mx-auto">
              <h2 className="text-center md:text-left w-full md:w-8/12 lg:w-full text-4xl md:text-5xl font-GodaBold uppercase text-ab-yellow-600">Deleted Scenes</h2>
              <div className="w-full md:w-8/12 lg:w-full mb-5 md:mb-10">
                <p className="text-center md:text-left font-CobeSemiBold text-xl leading-6 md:text-2xl">Watch all episodes and bonus content here</p>
              </div>
                <CarouselSlider carousel={postData} slidesPerPage={2} type="postData" />
            </div>
          </div>

          
        </div>
      </Layout>
    )
  }
  
  export default AboutPage 
  
  export const pageQuery = graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      webiny {
        listAlphaBetasPosts(sort: createdOn_DESC) {
          data {
            id
            title
            slug
            heroImage
            description
            createdOn
            featuredImage
            textTracks
            visibility
            video
          }
        }
        listAlphaBetasCharacters(sort: createdOn_ASC) {
          data {
            id
            entryId
            characterName
            characterShortBio
            slug
            characterVoicedBy
            characterVoicedBySlug
            characterFirstAppearance
            characterVoicedByLink
            characterProfileImage
            characterImage
            characterAdditionalAsset
          }
        }
      }
    }
  `
  